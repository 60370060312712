import React from "react"
import { graphql } from "gatsby"
import {ParsedText} from "components/content/ParsedText"
import * as styles from "./alert.module.scss"

const AlertBanner = ({ bannerContent }) => {
  const { alert_text, svg_icon, link } = bannerContent

  return (
    <div className={styles.emergencyTicker}>
      <div className={styles.marquee}>
        {svg_icon && <ParsedText content={svg_icon} />}
        <a href={link}>{alert_text}</a>
      </div>
    </div>
  )
}

export const strapiQuery = graphql`
  fragment StrapiAlertBannerFragment on STRAPI_ALERT {
    alert_banner {
      alert_text
      background_color
      id
      link
      svg_icon
    }
    enable_alert_banner
    id
  }
`

export default AlertBanner
