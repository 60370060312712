import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLogos } from "hooks/queries/settings/useLogos"
import { useMainOfficeNumber } from "hooks/useMainOfficeNumber"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { useLayout } from "contexts/components/LayoutContext"
import NavigationTile from "components/navigation-tile/NavigationTile"
import { Content } from "components/content/Content"
import { ButtonParser } from "components/button/ButtonParser"
import * as styles from "./hero.module.scss"

const Hero = ({ banner, customCTA }) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { mainOfficeNumber } = useMainOfficeNumber({})
  const trackingPhone = mainOfficeNumber || "(207) 888-8888"
  const data = useStaticQuery(getData)
  const { navigation_tiles } =
    data.allStrapiComponentContentBlockNavigationBlock.nodes[0]
  const { background_image, heading, sub_heading_rich, hero_buttons } = banner
  const max2Buttons = hero_buttons
    ? hero_buttons?.filter((_, index) => index < 2)
    : []

  const { headerLogo } = useLogos()

  const backgroundImageUrl = background_image?.localFile.publicURL

  const backgroundImageCSSVariable = useBackgroundImage({
    backgroundImageUrl,
    variable: "hero",
  })

  const bgOverlay = "PrimaryColor"

  const getNavTiles = () => {
    if (navigation_tiles) {
      // Navigation tiles are a specific type of content block that is returned by Content blocks.
      return (
        <div className="nav-tile-wrapper">
          {navigation_tiles.map(navTile => {
            return <NavigationTile key={navTile.id} tile={navTile} />
          })}
        </div>
      )
    }
  }

  return (
    <div className={styles.hero} id="hero-override">
      {backgroundImageUrl && (
        <div
          className={styles.heroBgImage}
          style={backgroundImageCSSVariable}
        />
      )}
      <div
        className={`${bgOverlay}Bg ${styles.opacity}`}
        id="hero-override"
      ></div>

      <section className={`container ${styles.bannerContent}`}>
        <img
          src={headerLogo.url}
          alt={headerLogo?.alternativeText ?? ""}
          className={styles.logo}
        />

        {heading && <h1>{heading}</h1>}
        {sub_heading_rich?.data?.sub_heading_rich && (
          <Content content={sub_heading_rich?.data?.sub_heading_rich} />
        )}

        {!!max2Buttons?.length && (
          <div className={`${styles.homePageBannerLinks} ${max2Buttons.length === 1 ? styles.singleButton : ''}`}>
            <ButtonParser
              buttons={max2Buttons}
              firstButtonClassName="primary-button"
              secondButtonClassName="white-accent-ghost-button"
              firstButtonId="hero-cta"
            />
          </div>
        )}
        {getNavTiles()}
      </section>
    </div>
  )
}

export default Hero

export const strapiQuery = graphql`
  fragment StrapiHeroBannerFragment on STRAPI__COMPONENT_BANNERS_HERO_BANNER {
    id
    heading
    sub_heading_rich {
      data {
        sub_heading_rich
      }
    }
    tagline
    hero_buttons {
      id
      associated_page {
        slug
      }
      anchor_reference
      button_text
      button_style
      custom_anchor_reference
      custom_call_tracking_number
      migrated_associated_page
      use_main_office_call_tracking_number
    }
    background_image {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, speed: 10, quality: 30 }
            webpOptions: { quality: 7 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
            layout: FULL_WIDTH
          )
        }
        publicURL
      }
    }
    secondary_image {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, quality: 70, speed: 10 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: NONE
            layout: FIXED
          )
        }
        publicURL
      }
    }
  }
`

const getData = graphql`
  query getStuff {
    allStrapiComponentContentBlockNavigationBlock {
      nodes {
        ...StrapiNavigationTilesFragment
      }
    }
  }
`
