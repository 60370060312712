import React, { Suspense } from "react"
import { graphql, useStaticQuery, PageProps } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIsSsr } from "assets/js/helper"
import Layout from "components/layout"
import SEO from "components/seo/seo"
import AlertBanner from "components/alert-banner/AlertBanner"
import ContentBlocks from "components/content-blocks/ContentBlocks"
import GoogleRating from "components/google-rating-banner/GoogleRating"
import Hero from "components/hero/Hero"
import { EMPTY_PAGE } from "assets/js/EmptyPage"

import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"

const GlobalFooter = React.lazy(
  () => import("../components/global-footer/GlobalFooter")
)

type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface IndexPageProps extends PageProps {
  pageContext: PageContext
}

const IndexPage: React.FC<IndexPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, linkedPagesUrlPathCodex } = pageContext
  const data = useStaticQuery(getHomePage)
  const [homepage] = data.allStrapiPage.nodes

  const { content, hero, global_footer, meta_data, custom_tracking_number } =
    homepage ?? EMPTY_PAGE
  const isSsr = useIsSsr()
  const { customCTAText, alertBanner, isAlertEnabled } = useGeneralSettings()

  const { googleReviewsUrl } = useIntegrationsSettings()

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      customTrackingNumber={custom_tracking_number}
      hash={hash}
      pathName={pathname}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location="/"
    >
      <SEO
        title={meta_data?.meta_title}
        description={meta_data?.meta_description}
        canonical={meta_data?.canonical_link}
        image={meta_data?.rich_data_image}
      />

      {hero && <Hero banner={hero} customCTA={customCTAText} />}
      {googleReviewsUrl && <GoogleRating url={googleReviewsUrl} />}

      {isAlertEnabled && alertBanner && (
        <AlertBanner bannerContent={alertBanner} />
      )}

      {content?.length > 0 && (
        <ContentBlocks strapiContent={content} isHome={true} />
      )}

      {!isSsr && global_footer?.content?.length > 0 && (
        <Suspense fallback={<div>Loading...</div>}>
          <GlobalFooter content={global_footer.content} />
        </Suspense>
      )}
    </Layout>
  )
}

const getHomePage = graphql`
  query Home {
    allStrapiPage(filter: { slug: { eq: "/" } }) {
      nodes {
        title
        slug
        alternating_content
        custom_tracking_number
        meta_data {
          meta_title
          meta_description
          rich_data_link
          canonical_link
          rich_data_image {
            localFile {
              publicURL
            }
          }
        }
        hero {
          ...StrapiHeroBannerFragment
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiLegacyPestLibraryFragment
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`

export default IndexPage
